.absolute {
  position: fixed;
  bottom: 0;
  left: 13vw;
  background: #fff;
  width: 87vw;
  padding: 1.5vw;
}
.customHtmlBlock {
  background: none;
}
.pageTitle {
  margin-right: 20px;
  font-size: 32px;
  color: var(--color-text);
  font-weight: bold;
  text-transform: uppercase;
}
.addBl {
  background: linear-gradient(
    310deg,
    rgb(104, 122, 151) 0%,
    rgb(44, 60, 87) 100%
  );
  color: #fff;
  padding: 12px 20px;
  border-radius: 7px;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  width: fit-content;
}
.changeBlock {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.deleteBlockSvg {
  margin-left: 0;
  margin-right: 0;
  fill: var(--color-text) !important;
  padding: 8px;
  cursor: pointer;
  width: 45px !important;
  height: 45px !important;
  border-radius: 5px;
}
.appI {
  width: 36px !important;
  height: 36px !important;
  margin-right: 15px;
  fill: rgb(18, 18, 18);
}
.blockSearch {
  padding: 10px;
  width: 300px;

  font-size: 16px;
  border-radius: 7px;
  box-shadow: var(--shadow);
  border: none;
}
.headerContButtons {
  display: flex;
  align-items: center;
  margin-left: auto;
}
.headerContButtons > div {
  cursor: pointer;
}
.watchLink {
  border: 1px solid var(--color-text);
  border-radius: 7px;
  color: var(--color-text);
  font-weight: 600;
  margin-right: 15px;
  padding: 12px 20px;
  text-transform: uppercase;
  font-weight: 500;
  font-size: 14px;
}
.changeBlock > span {
  font-weight: 500;
  background: #fafafa;
  margin-right: 15px;
  height: 45px;
  display: flex;
  align-items: center;
  color: var(--color-text);
  padding: 0 20px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid #e9e9e9;
}
.comment {
  margin-right: 20px;
  color: var(--color-text);
  opacity: 0.7;
}
.comment b {
  margin-right: 5px;
  font-weight: 600;
}
.changeBlock > span > i {
  font-weight: 500;
  margin-left: 10px;
  color: var(--color-text);
  opacity: 0.7;
  font-style: normal;
}
.childIcon {
}
.headerContBody {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 2px dashed #0370f3;
  align-items: center;
}
.headerContBody p {
  font-size: 32px;
  font-weight: 600;
  color: var(--color-text);
  margin-right: 20px;
}
.imagesForType {
  flex: 1 1;
  overflow: hidden;
  position: relative;
  height: 2.5vw;
  margin-left: 1vw;
  margin-right: 1vw;
}
.contChild > svg {
  fill: var(--color-text);
  opacity: 0.7;
}
.imagesForType img {
  position: absolute;
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 5px;
  object-fit: cover;
}
.contChild article {
  font-size: 16px;
  color: var(--color-text);
  font-weight: 500;
}
.contChild {
  background: #fff;
  padding: 10px;
  border-radius: 14px;
  margin-bottom: 10px;
  transition: 0.3s;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: var(--shadow);
}
.contChild:hover {
  transform: translateY(-5px);
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.3);
}
.webs {
  width: 45px !important;
  margin-right: 0px;

  fill: var(--color-text) !important;
  padding: 5px;
  border-radius: 5px;
  height: 45px !important;
}
.contChild h4 {
  font-size: 24px;
  margin-right: 15px;
  background: var(--color-text);
  border-radius: 7px;
  width: 45px;
  color: #fff;

  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.contChild p {
  font-size: 16px;
  font-weight: 600;
  color: var(--color-text);

  margin-right: 20px;
}
.blocksBlock {
  padding-top: 20px;
}
