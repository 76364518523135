@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
.App {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
* {
  --background-app: rgb(15, 18, 20);
  --color-text: rgb(52, 71, 103);
  --color-svg: rgb(58, 65, 111);
  --color-text-tab: hsl(215, 15%, 75%);
  --backround-block: linear-gradient(
    0deg,
    rgba(0, 59, 117, 0.1),
    rgba(15, 18, 20, 0.5)
  );
  --color-active: hsl(210, 100%, 70%);
  --menu-background: rgb(17, 23, 29);
  --gray: rgb(18, 18, 18);
  --shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}
.container {
  top: 0;
  left: 0;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.container {
  padding: 0 30px;
  padding-bottom: 30px;
}
.flexibility {
  display: flex;
}
body {
  background: rgb(248, 249, 250);
  width: 100vw;
  height: 100vh;
}
#root {
  background: rgb(248, 249, 250);
  width: 100vw;
  height: 100vh;
}
*::-webkit-scrollbar {
  width: 0.20833333333333334vw;
}
*::-webkit-scrollbar-track {
  background-color: #ffffff;
}
*::-webkit-scrollbar-thumb {
  background: #0370f3;
  border-radius: 0.5208333333333333vw;
}
.rightCont {
  flex: 1 1;
}
* {
  padding: 0;
  margin: 0;

  font-family: "Roboto", Helvetica, Arial, sans-serif;
  text-decoration: none;
  --big-button-disabled-background: #00c2ff;
  --big-button-active-background: #0094ff;
  --small-button-disabled-background: #ffffff;
  --small-button-active-background: #00c2ff;

  box-sizing: border-box;
}
.button {
  padding: 1vw 4vw;
  cursor: pointer;
  border-radius: 0.5208333333333333vw;
  background: #0094ff;
  color: #fff;
  font-weight: bold;
  width: fit-content;
  font-size: 1vw;
  transition: 0.5s;
} /* ₽ 21.560.234,00 */

.financeLeft h2 {
  font-size: 40px;
  font-weight: bold;
}
.financeLeft p {
  background: linear-gradient(90deg, #0370f3 0%, #00a7ff 45%, #00c0cc 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 2.083333333333333vw;
  font-weight: bold;
  display: table;
}
.activeFinance {
  display: flex;
  align-items: center;
}
.activeFinance span {
  margin-left: 1.0416666666666665vw;
  padding: 0.625vw 0.78125vw;
  border-radius: 0.5208333333333333vw;
  font-weight: 600;
  border: 0.052083333333333336vw solid #afb2ce;
  color: #636177;
  font-size: 0.625vw;
}
textarea {
  outline: none;
}
.rightCont {
  flex: 1 1;

  height: 100vh;
  transition: 0s;
  display: flex;
  flex-direction: column;
}
.grM {
  background: #fff;
  border-radius: 1.0416666666666665vw;
  padding: 1.0416666666666665vw;
  display: flex;
  flex-direction: column;
  padding-right: 1.8vw;
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.2);
}
.mainGr {
  flex: 1 1;
}
.rnc__notification-item--success {
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
  border-left: 0.4166666666666667vw solid #0370f3 !important;
}
.rnc__notification-item--success .rnc__notification-timer {
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
}
.lastOrders {
  padding: 1.0416666666666665vw;
  border-radius: 1.0416666666666665vw;
  background: #fff;
}
input {
  padding: 0.8vw;
  font-size: 0.9vw;
  margin-left: 1vw;
  border-radius: 5px;
  border: 1px solid black;
}
.financeCont {
  margin-bottom: 10px;
}
.grM .recharts-surface {
  overflow: visible;
  margin-left: 1.0416666666666665vw;
}
.grM h3 {
  margin-bottom: 1.3020833333333335vw;
  margin-left: 0.5208333333333333vw;
  font-size: 1.2vw;
  margin-top: 0.5208333333333333vw;
}
.desc {
  color: #afb2ce;
} /* Frame 408496618 */

.custom-tooltip {
  background: #fff;
  border-radius: 0.5208333333333333vw;
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.2);
  padding: 0.5208333333333333vw;
}
.lastOrders ul {
  list-style-type: none;
}
.lastOrders li {
  display: flex;
}
.arc {
}
.bottomInfo {
  display: flex;
}
.button:hover {
  background: #00c2ff;
}
.App {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  position: relative;
  /* background: url(./img/fon14.png); */

  /* background-position-x: -21vw;
  background-position-y: -19vw; */
  /* background-size: 100vw; */
}
.flexibility {
  background: rgb(248, 248, 248);
}
a {
  color: #525252;
}
a:link {
  color: #525252;
}
a:visited {
  color: #525252;
}
a:hover {
  color: #525252;
}
a:active {
  color: #525252;
}
.sub_titles h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 2.5vw;
  margin-bottom: 0.5vw;

  color: #525252;
}
.subscribers {
  padding: 2vw 3vw;
  display: flex;
  align-items: center;
  background-color: #f6f7fb;
  width: 80vw;
}
.sub_titles * {
  text-align: left;
}
.sub_titles > p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  margin-bottom: 2vw;
  width: 22vw;
  color: #525252;
}
.sub_titles {
  margin-right: 5.3vw;
}
.xls {
  display: flex;
  align-items: center;
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1vw;
  padding: 0.5vw 1.3vw;
  padding-right: 0.8vw;
  width: fit-content;
  /* identical to box height */
  background: #ffffff;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw 2.03125vw
    rgba(19, 44, 73, 0.15);
  border-radius: 0.5vw;
  cursor: pointer;
  color: #525252;
}
.xls img {
  margin-left: 0.8vw;
  width: 2vw;
}
.xls:hover {
  transform: translateY(-5px);
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw 2.5vw
    rgba(19, 44, 73, 0.3);
}
.sub_total {
  display: flex;
  align-items: center;
}
.sub_total_num h3 {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 3.5vw;
  line-height: 2vw;
  /* identical to box height */

  text-align: center;

  color: #000000;
}
.sub_total_num p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 500;
  font-size: 1.5vw;

  /* identical to box height */

  text-align: center;

  color: #000000;
}
.sub_total_num {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 10;
}
.sub_circle {
  width: 15vw;
  z-index: 5;
  position: relative;
  height: 15vw;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background: #00c2ff;
  border: 0.4166666666666667vw solid rgba(196, 235, 248, 0.67);
  border-radius: 50%;
  overflow: hidden;
}
/* for the pen */

/* waves */

.sub_circle:after,
.sub_circle:before {
  content: "";
  position: absolute;

  left: 50%;
  min-width: 50vw;
  min-height: 50vw;
  background-color: #ffffff;
  animation-name: rotate;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.sub_circle:before {
  bottom: 6vh;
  border-radius: 45%;
  animation-duration: 10s;
}

.sub_circle:after {
  bottom: 5vh;
  opacity: 0.5;
  border-radius: 47%;
  animation-duration: 10s;
}
@keyframes rotate {
  0% {
    transform: translate(-50%, 0) rotateZ(0deg);
  }
  50% {
    transform: translate(-50%, -2%) rotateZ(180deg);
  }
  100% {
    transform: translate(-50%, 0%) rotateZ(360deg);
  }
}
.sub_description {
  background: #ffffff;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw 1.1458333333333333vw
    rgba(0, 83, 139, 0.12);
  padding: 1.2vw 2vw;
  padding-left: 4vw;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  transform: translateX(-2vw);
  position: relative;
}
.sub_description img {
  width: 3vw;
}
.date {
  margin-left: 0.6vw;
}
.date p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.5vw;
  line-height: 1.4vw;

  /* identical to box height */

  color: #000000;
}
.date span {
  font-style: normal;
  font-weight: 600;
  font-size: 0.8vw;

  letter-spacing: 0.045em;

  color: #5b7384;
}
.date * {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.sub_description .line {
  width: 0.6vw;
  height: 3.7vw;
  background: #00c2ff;
  border-radius: 2vw 0 0 2vw;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.days_gone {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 5vw;
}
.days_gone .line {
  width: 6.5vw;
  height: 0.5vw;
  background: #00c2ff;
  border-radius: 2vw 2vw 0 0;
}
.days_gone_body {
  background: #ffffff;
  box-shadow: 0.052083333333333336vw 0.052083333333333336vw 1.1458333333333333vw
    rgba(0, 83, 139, 0.12);
  padding: 1.6vw 1.1vw;
  border-radius: 1vw;
}
.days_gone_body p {
  font-style: normal;
  font-weight: 500;
  font-size: 0.9vw;
  max-width: 6vw;

  text-align: center;

  color: #000000;
}
.days_gone_body h3 {
  font-style: normal;
  font-weight: 700;
  font-size: 3.2vw;
  line-height: 3.4vw;
  /* identical to box height */

  text-align: center;

  color: #00c2ff;
}
.raz {
  width: 80vw;
  position: fixed;
  bottom: -4vw;
  right: 0;
}
.pop_send {
  background: rgba(23, 42, 56, 0.27);
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: 900;
  top: 0;
  left: 0;
}
.pop_send_body {
  background: #fff;
  border-radius: 2vw;
  width: 32vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2vw;
  position: absolute;
  bottom: 7vw;
  left: 4.5vw;
  background: url(./imgs/send_pop.png);
  background-size: cover;
  box-sizing: border-box;
}
.pop_send_body p {
  font-family: "Mont";
  font-style: normal;
  font-weight: 700;
  font-size: 1.2vw;
  text-align: center;
  width: 26vw;

  color: #525252;
}
.pop_send_body b {
  font-weight: 900;
}
.opacity_text {
  font-family: "Mont";
  font-style: normal;
  font-weight: 600;
  font-size: 1vw;
  margin-top: 1vw;
  margin-bottom: 1.3vw;
  /* identical to box height */

  color: #acacac;
}
.input_text {
  width: 100%;
  font-size: 1vw;
  border-radius: 1vw;
  padding: 1vw;
  background: #ffffff;
  border: 0.052083333333333336vw solid #e8e8e8;
  outline: none;
}
.save_send {
  background: #00c2ff;
  border-radius: 1vw;
  width: 100%;
  padding: 1vw;
  font-size: 1vw;
  color: #fff;
  text-align: center;
  cursor: pointer;
  margin-top: 1vw;
}
.container {
  animation: fadePage 0.7s ease;
}
@keyframes fadePage {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.showSlider * {
  font-family: Inter !important;
}
.showSlider {
  background: #f2f4f8;
  overflow-y: scroll;
  max-height: 100vh;
  overflow-x: hidden;
}
.showSliderHeader > img {
  width: 6vw;
}
.showSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showCSlider::-webkit-scrollbar-thumb {
  background: #0370f3;
}
.showSliderHeader {
  display: flex;
  align-items: center;
  padding: 1vw 4vw;
  width: 100vw;
  border-bottom: 0.052083333333333336vw solid #d9e3ed;
}
.showLink {
  background: #ffffff;
  box-shadow: 0.052083333333333336vw 0.35763072916666666vw 1.78815625vw
    rgba(83, 131, 203, 0.25);
  border-radius: 0.5208333333333333vw;
  padding: 0.5vw;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  color: black;
  margin-left: 1vw;
  text-decoration: none;
}
.showLink img {
  margin-left: 0.5vw;
  width: 2vw;
}
.showSliderHeader > p {
  font-weight: bold;
  margin-left: 10vw;
  font-size: 1vw;
}
.showbutton {
  background: #0370f3;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;
  margin-left: 4vw;
  margin-right: 1vw;
}
.showBusiness {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0.052083333333333336vw 0.330109375vw 0.900296875vw
    rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  font-size: 0.8vw;
  padding: 1vw 1.5vw;
  border-radius: 6vw;

  margin-right: 1vw;
}
.showContacs {
  margin-left: auto;
}
.showContacs a span {
  color: #3e95ff;
}
.showContacs a {
  font-size: 1.5vw;
  color: black;
  text-decoration: none;
  font-weight: bold;
}
.showContacs p {
  text-align: right;
  font-size: 0.8vw;
  color: #626262;
}
.showSlider h2 {
  font-size: 3.5vw;
  text-transform: uppercase;
  margin-left: 4vw;
  margin-top: 4vw;
  display: flex;
  margin-bottom: 2vw;
  color: #0370f3;
}
.showSlider h2 div {
  margin-right: 1vw;
}
.showSlider h2 span {
  color: #31363d;
}
.sliderBody {
  padding: 0 4vw;
}
.leftSliderBody > p {
  font-size: 1.2vw;
  font-weight: 600;
  color: #31363d;
  padding-top: 1vw;
  border-top: 0.052083333333333336vw solid #b5c0d6;
}
.leftSliderBody > p span {
  color: #0370f3;
}
.sliderButtons {
  display: flex;
  align-items: center;
  margin-top: 2vw;
  margin-bottom: 2vw;
}
.sliderButtons img:hover {
  scale: 1.05;
}
.sliderButtons img {
  width: 4vw;
  cursor: pointer;
}
.sliderButtons p {
  margin: 0 2vw;
  font-size: 1vw;
  color: #31363d;
}
.slShowEm {
  display: flex;
  width: 45vw;
  align-items: flex-start;
  justify-content: space-between;
}
.bigSnt {
  width: 72%;
  cursor: zoom-in;

  object-position: 50% 0;
}
.smallSnt {
  width: 20.5%;
  aspect-ratio: 1/4;
  border-bottom: 0.26041666666666663vw solid #fff;
  object-fit: cover;
  object-position: 50% 0;
  cursor: zoom-in;
}
.bigSnt:hover,
.smallSnt:hover {
  transform: translateY(-5px);
}
.currentShow p {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5vw;
  font-weight: 600;
  color: #31363d;
}
.currentShow img {
  width: 2vw;
}
.sliderBody {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
.currentShow {
  cursor: pointer;
  width: 39vw;
  border-bottom: 0.052083333333333336vw solid rgba(3, 112, 243, 0.3);
}
.currentShow > p {
  padding: 1vw;
  border-radius: 0.5vw;
}
.avc {
  filter: brightness(5);
  transform: rotate(180deg);
}
.rightSliderBody {
}
.below {
  padding: 1.5vw;
  border-radius: 1.5vw;
  background: #fff;
}
.rightSliderBody h4 {
  text-transform: uppercase;
  font-size: 2.5vw;
  color: #31363d;
  margin-top: 0.5vw;
}
.rightSliderBody > div > p {
  font-size: 1.3vw;
  margin-top: 0.5vw;
  color: #31363d;
  margin-bottom: 1vw;
}
.currentShow:last-child {
  border-bottom: none;
}
.snBtn {
  background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
  box-shadow: 0.052083333333333336vw 0.5729166666666666vw 1.5625vw
    rgba(5, 255, 0, 0.3);
  border-radius: 6vw;
  padding: 1.5vw 2vw;
  cursor: pointer;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 0.05vw;
  width: fit-content;
}
.rightSliderBody h3 {
  font-size: 2.1vw;
  font-family: Inter;
  color: #31363d;
  padding-top: 1.5vw;
  margin-top: 2.5vw;
  margin-bottom: 0.5vw;
  border-top: 0.052083333333333336vw solid #b5c0d6;
}
.uwa {
  color: #96a2bc;
  font-size: 1vw;
  display: block;
  margin-bottom: 1.5vw;
}
.currentShow ul {
  list-style-type: none;
  margin-top: 0.5vw;
  overflow: hidden;
}
.currentShow ul li {
  padding: 0.5vw 1vw;
  font-size: 1vw;
  color: #31363d;
  font-weight: 600;
}
.currentShow ul li:hover {
  transform: translateX(5px);
}
.showCSlider {
  position: fixed;
  z-index: 5555;
  backdrop-filter: blur(15px);
  display: flex;

  justify-content: center;
  width: 100vw;
  padding-top: 3vw;
  overflow-y: scroll;
  height: 100vh;
  background: rgba(0, 0, 0, 0.2);
}
.showCSlider img {
  max-width: 80vw;
  border-radius: 0.78125vw;
  margin-bottom: 3vw;
  overflow: hidden;
}
.showFooter {
  padding: 2vw 1.5vw;
  border-top: 0.052083333333333336vw solid #89a6e6;
  margin-top: 2vw;
  background: #fff;
}
.showFooterBody {
  display: flex;
  align-items: center;
}
.showFooterBody > img {
  width: 8vw;
}
.showFooterBody > p {
  font-weight: bold;
  font-size: 1.5vw;
  color: #31363d;
  margin-left: 10vw;
  margin-right: 2vw;
}
.footerLinks {
  margin-top: 2vw;
}
.footerLinks a {
  color: #626262;
  margin-right: 2vw;
  font-size: 0.8vw;
  text-decoration: none;
}
.footerLinks span {
  font-size: 0.6vw;
  color: #626262;
}
.addLink p {
  font-size: 1.3vw;
  font-weight: bold;
  color: #3e95ff;
  margin-bottom: 0.5vw;
}
.addLink {
  margin-right: 1.5vw;
}
.addLink a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #31363d;
  font-weight: 500;
  padding: 0.5vw;
  box-shadow: 0.052083333333333336vw 0.35763072916666666vw 1.78815625vw
    rgba(83, 131, 203, 0.25);
  border-radius: 0.5vw;
  background: #fff;
  font-size: 0.9vw;
}
.addLink div {
  display: flex;
}
.addLink a img {
  width: 2vw;
  margin-left: 0.5vw;
}
.addLink div a {
  margin-right: 1vw;
}
.addLink a:hover {
}
.addLink a:hover img,
.showLink:hover img {
  transform: rotate(360deg);
}
.bigi {
  width: 100%;
}
.snBtn,
.showBusiness {
  position: relative;
  cursor: pointer;
  overflow: hidden;
}
.showbutton {
  cursor: pointer;
}
.snBtn .flare,
.showBusiness .flare {
  position: absolute;
  top: 0;
  height: 100%;
  width: 2.34375vw;
  transform: skewX(-45deg);
  animation: flareAnimation;
  left: -150%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.4)
  );
  animation: flareAnimation 3s infinite linear;
}
@keyframes am {
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
@keyframes flareAnimation {
  0% {
    left: -150%;
  }
  100% {
    left: 150%;
  }
}
@media (max-width: 640px) {
  .sliderBody {
    flex-direction: column;
  }
  .below {
    width: 100%;
    padding: 4vw;
  }
  .rightSliderBody h4 {
    text-transform: uppercase;
    font-size: 5.5vw;
    color: #31363d;
    margin-top: 0.5vw;
  }
  .rightSliderBody > div > p {
    font-size: 3.3vw;
    margin-top: 0.5vw;
    color: #31363d;
    margin-bottom: 4vw;
  }
  .currentShow p {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 5.5vw;
    font-weight: 600;
    width: 100%;
    color: #31363d;
  }
  .rightSliderBody {
    width: 100%;
  }
  .currentShow {
    width: 100%;
  }
  .currentShow img {
    width: 7vw;
  }
  .currentShow > p {
    padding: 3vw;
  }
  .currentShow ul li {
    padding: 1.5vw 3vw;
    font-size: 4.5vw;
    color: #31363d;
    font-weight: 600;
  }
  .slShowEm {
    flex-direction: column;
  }
  .bigSnt {
    width: 100%;
    margin-bottom: 7vw;
  }
  .slShowEm {
    width: 100%;
  }
  .smallSnt {
    width: 100%;
  }
  .showSlider h2 {
    flex-direction: column;
    text-align: center;
    font-size: 6.5vw;
    margin-top: 12vw;
    margin-bottom: 6vw;
    width: 92vw;
  }
  .leftSliderBody > p {
    font-size: 4.2vw;
    font-weight: 600;
    color: #31363d;
    padding-top: 5vw;
    border-top: 0.052083333333333336vw solid #b5c0d6;
    text-align: center;
  }
  .sliderButtons img {
    width: 18vw;
    cursor: pointer;
  }
  .sliderButtons {
    width: 100%;
    justify-content: space-between;
    margin-top: 6vw;
    margin-bottom: 6vw;
  }
  .sliderButtons p {
    margin: 0 2vw;
    font-size: 4vw;
    color: #31363d;
  }
  .showSliderHeader > img {
    width: 20vw;
  }
  .showLink img {
    margin-left: 0;
    width: 8vw;
  }
  .showLink {
    background: #ffffff;
    box-shadow: 0.052083333333333336vw 0.35763072916666666vw 1.78815625vw
      rgba(83, 131, 203, 0.25);
    border-radius: 50%;
    padding: 0.5vw;
    display: flex;
    align-items: center;
    font-size: 0.8vw;
    color: black;
    margin-left: 1vw;
    text-decoration: none;
  }
  .showBusiness,
  .showbutton {
    display: none;
  }
  .showContacs {
    display: none;
  }
  .showSliderHeader > p {
    font-weight: bold;
    margin-left: 31vw;
    font-size: 2.5vw;
    margin-right: 0vw;
    width: 20vw;
    text-align: center;
  }
  .showSliderHeader {
    display: flex;
    align-items: center;
    padding: 3vw 4vw;
    width: 100vw;
    border-bottom: 0.052083333333333336vw solid #d9e3ed;
  }
  .below {
    margin-top: 6vw;
  }
  .smallSnt {
    aspect-ratio: 1/2;
  }
  .rightSliderBody h3 {
    font-size: 4.5vw;
    font-family: Inter;
    color: #31363d;
    text-align: center;
    padding-top: 5.5vw;
    margin-top: 7.5vw;
    margin-bottom: 0.5vw;
    border-top: 0.052083333333333336vw solid #b5c0d6;
  }
  .uwa {
    color: #96a2bc;
    font-size: 3.5vw;
    text-align: center;
    display: block;
    margin-top: 3vw;
    margin-bottom: 4.5vw;
  }
  .snBtn {
    background: linear-gradient(89.22deg, #29ae13 14.97%, #56bf04 99.48%);
    box-shadow: 0.052083333333333336vw 0.5729166666666666vw 1.5625vw
      rgba(5, 255, 0, 0.3);
    border-radius: 24vw;
    padding: 5.5vw 2vw;
    cursor: pointer;
    width: 100%;
    text-align: center;
    color: #fff;
    margin-top: 7vw;
    text-transform: uppercase;
    letter-spacing: 0.05vw;
  }
}
