.selectMain {
  width: 20vw;
  font-size: 0.9vw;
  position: relative;
  border: 1px solid #3e95ff;
  border-radius: 0.5vw;
  top: 0;
}
.selectVisible {
  padding: 0.7vw 1vw;
  background: #fff;
  font-weight: 600;
  border-radius: 0.5vw;
}
.selectHide div {
  padding: 0.7vw 1vw;
  background: #fff;
  transition: 0.3s;
  cursor: pointer;
}
.selectHide div:hover {
  color: #3e95ff;
}
.selectHide div:last-child {
  border-bottom: none;
}
.selectHide div:nth-child(2n -1) {
  background: rgb(244, 244, 244);
}
.selectHide {
  border-radius: 0 0 0.5vw 0.5vw;
  overflow: hidden;
  max-height: 0;
  position: absolute;
  top: 2.5vw;
  width: 20vw;
  left: -1px;
  overflow-y: scroll;
  border-left: 1px solid #3e95ff;
  border-right: 1px solid #3e95ff;
}
.selectMain:hover .selectHide {
  max-height: 20vw;
}

.selectMain * {
  transition: 0.3s;
}
.selectMain:hover .selectVisible {
  border-radius: 0.5vw 0.5vw 0 0;

  transition: 0s;
}
.selectMulElem {
  display: flex;
  align-items: center;
}
.selectMulElem input {
  margin-right: 1vw;
  min-width: 1.2vw;
  display: block;
  min-height: 1.2vw;
  cursor: pointer;
}
