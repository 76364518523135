.osnCont {
}
.osnCont p {
  font-size: 20px;
  margin-bottom: 15px;
  font-weight: 700;
}
.personalGr {
  flex: 1 1;
}
.osnPElem:hover {
  transform: translateY(-5px);
  cursor: pointer;
}
.Personal {
  display: flex;
  flex-direction: column;
}

.rebot {
  width: 18vw;
}
.timespend {
  /* Frame 408496345 */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0.5vw;
  flex: 1 1;
  background: linear-gradient(312.27deg, #0370f3 -5.24%, #03abf3 100%);
  border-radius: 10px;

  /* Inside auto layout */
}
.lastOrders ul {
  overflow-y: scroll;
  flex: 1 1;
  max-height: 400px;
  margin-bottom: 10px;
}
.lastOrders {
  overflow: hidden;
}
.lastOrders ul li {
  cursor: pointer;
}
.lastOrders ul::-webkit-scrollbar {
  display: none;
}
.timespend span {
  text-align: center;
  font-size: 0.8vw;
  color: #fff;
}
.timespend p {
  color: #fff;
  font-size: 2.5vw;
  font-weight: bold;
}
.osnPElem {
  display: flex;
  width: 100%;
  padding: 1.5625vw;
  margin-right: 1.5vw;
  align-items: flex-end;
  background: #fff;
  border-radius: 1vw;
  justify-content: space-between;
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.2);
}
.customGr {
  margin-left: 0;

  border-radius: 0 0 0.78125vw 0;
  overflow: hidden;
}
.osnP {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 30px;
}
.osnCont div {
}
.osnPrice {
  font-size: 24px;
  display: flex;
  align-items: center;
  font-weight: bold;
}
.osnPrice img {
  margin: 0 0.78125vw;
  margin-right: 0.3125vw;
  width: 30px;
}
.osnP {
  margin-bottom: 30px;
}
.osnPrice span {
  font-size: 18px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: #00ab4f;
}
.osnCont article {
  color: #a2a3b1;
  font-size: 0.8333333333333334vw;
  font-weight: 600;
}
.bottomInfo {
  display: grid;
  grid-template-columns: 2fr 1fr 1.5fr;
  column-gap: 30px;
  flex: 1 1;
}
.lastOrders h3,
.Personal h3 {
  margin-bottom: 1.3vw;

  font-size: 1.2vw;
  margin-top: 0.5vw;
}
.Personal h3 {
  margin-left: 1.25vw;
}
.lastOrders span {
  color: #389541;
  font-size: 1.0416666666666665vw;
  margin-left: 1.0416666666666665vw;
  font-weight: bold;
  margin-left: auto;
}
.lastOrders li img {
  width: 2.083333333333333vw;
  margin-right: 0.5208333333333333vw;
}
.lastOrders div {
  color: #a2a3b1;
  font-size: 0.7291666666666666vw;
}
.lastOrders li p {
  color: black;
  font-size: 0.9375vw;
}
.Personal {
  padding: 1vw;
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.2);
  background: #fff;
  border-radius: 1.0416666666666665vw;
}
.lastOrders li {
  font-size: 0.9375vw;
  border-bottom: 0.052083333333333336vw solid #f4f3ff;
  padding-bottom: 0.5208333333333333vw;
  margin-bottom: 0.5208333333333333vw;
}
.lastOrders li {
  align-items: center;
}
.lastOrders {
  box-shadow: 0.052083333333333336vw 0.3125vw 1.5625vw rgba(58, 95, 190, 0.2);
}
.customGr {
  width: 8vw;
  height: 6vw;
}
@media (max-width: 1680px) {
  .osnPrice {
    flex-direction: column;
    align-items: flex-start;
  }
  .osnPrice img {
    margin-left: 0;
  }
  .osnCont article {
    display: none;
  }
  .osnPrice span {
    margin-bottom: 0.78125vw;
  }
  .osnCont p {
    margin-bottom: 10px;
  }
}
.orderItem {
  transition: 0.3s;
}
.orderItem:hover {
  background: #f4f3ff;
  cursor: pointer;
}
.newMain {
  padding-top: 1vw;
}
.newMain h1 {
  color: var(--color-text);
  font-size: 2vw;
}
.svodStat {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1vw;
  row-gap: 1vw;
  margin-top: 2vw;
}
.svodStat > div {
  background: #fff;
  color: var(--color-text);
  padding: 1vw;
  border-radius: 0.7vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  display: flex;
  justify-content: space-between;
}
.svodCont p {
  font-weight: 600;
  opacity: 0.7;
  font-size: 0.9vw;
  margin-bottom: 0.2vw;
}
.svodCont article {
  font-weight: 700;
  font-size: 1.2vw;
}
.svodCont article span {
  margin-left: 0.3vw;
  font-size: 0.9vw;
  font-weight: 500;
  color: rgb(95, 226, 8);
}
.svodIcon {
  background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  border-radius: 0.7vw;
  height: 2.7vw;
  width: 2.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.5vw;
  font-weight: 500;
}
.hello {
  display: flex;
}
.hello model-viewer {
  height: 100%;
  width: 100%;
}
.corcod {
  background: #fff;
  border-radius: 1vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  padding: 1.2vw;
}
.lastSales {
  width: 100%;
  background: #fff;
  margin-top: 1vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  padding: 1vw 0;
  padding-bottom: 0.5vw;
  border-radius: 0.7vw;
}
.lastSales > p {
  color: var(--color-text);
  font-weight: 600;
  font-size: 0.9vw;
  opacity: 0.7;
  margin-left: 1vw;
  margin-bottom: 0.5vw;
}
.lastSalesBody {
}
.ordIcon svg {
  fill: #fff;
  width: 60%;
  height: 60%;
}
.ordIcon {
  width: 2vw;
  height: 2vw;
  background: var(--color-text);
  opacity: 0.7;
  margin-right: 1vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.orderItem article {
  font-size: 0.7vw;
  opacity: 0.7;
}
.newMain {
  position: relative;
}
.hello {
  display: grid;
  grid-template-columns: 2fr 1fr;
}
.gridRobot {
  display: grid;
  grid-template-columns: 0.9fr 1fr;
}

.ordStatus {
  font-weight: 500;
  font-size: 0.9vw;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: var(--color-text);
}
.ordTime {
  margin-left: 3vw;
}
.ordSumm {
  font-weight: 500;
  font-size: 0.9vw;
  color: var(--color-text);
}
.orderItem:last-child {
  border-bottom: none;
}
.orderItem {
  display: flex;
  align-items: center;
  padding: 0.5vw 1vw;
  border-bottom: 1px solid #e4e4e4;
}
.ordStatus {
  margin-left: auto;
}
.container {
  max-height: 100vh;
  overflow-y: auto;
}
.downCont {
  margin-top: 2vw;

  display: grid;
  grid-template-columns: 1.5fr 2fr;
  width: 100%;
  column-gap: 2vw;
}

.downCont .personalGr {
  height: 20vw;
}
.downCont p {
  background: var(--color-text);
  border-radius: 0.7vw;
  width: 100%;
  height: 20vw;
  padding: 1vw;
  color: #fff;
}
.downCont .ddd {
  background: #fff;
  border-radius: 0.7vw;
  padding: 1vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}
.od > span {
  margin-bottom: 1.5vw;
  font-size: 0.8vw;
  color: var(--color-text);
  opacity: 0.7;
  display: block;
}
@keyframes hope {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
.seeMore {
  position: absolute;
  top: 1.5vw;
  font-size: 0.7vw;
  border-radius: 0.3vw;
  color: var(--color-text);
  border: 1px solid #e4e4e4;
  padding: 0.5vw;
  display: flex;
  right: 1vw;
  cursor: pointer;
  z-index: 5;
  transition: 0.3s;
  align-items: center;
}
.seeMore:hover {
  border: 1px solid var(--color-text);
}
.seeMore svg {
  margin-right: 5px;
  width: 17px;
  height: 17px;
}
.od {
  position: relative;
  padding: 1.5vw;
  padding-bottom: 1vw;
  background: #fff;
  border-radius: 0.7vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  color: var(--color-text);
}
.od h3 {
  margin-bottom: 0.1vw;
  font-size: 1vw;
}
.hotspot {
  display: block;
  opacity: 0;
  transition: 0.5s;
  transform-origin: right bottom;
  border-radius: 10px;
  border: none;
  background-color: blue;
  box-sizing: border-box;
  pointer-events: none;
}

.hotspot[slot="hotspot-hand"] {
  --min-hotspot-opacity: 0;
  background: none;
}

.hotspot[slot="hotspot-foot"]:not([data-visible]) {
  background-color: transparent;
  border: 3px solid blue;
}

.annotation {
  background-color: #fff;

  width: 8vw;

  border-radius: 1vw 1.5vw 0 1vw;
  color: var(--color-text);
  font-size: 0.8vw;
  font-weight: 500;
  box-shadow: var(--shadow);
  padding: 0.5vw;
}
.lining {
  width: 100%;
  aspect-ratio: 1/1;
}
.corcod h3 {
  color: var(--color-text);
  font-size: 0.9vw;

  font-weight: 600;
  margin-top: 0.5vw;
}
.lastf {
  margin-top: 0.1vw;
  font-size: 0.8vw;
  color: var(--color-text);
  font-weight: 500;
  opacity: 0.7;
}
.corcod {
  display: flex;
  flex-direction: column;
}
.corcod .newplan {
  margin-top: auto;
  text-align: center;
  margin-bottom: auto;
  background: linear-gradient(312.27deg, #0370f3 -5.24%, #03abf3 100%);
}
.flexEnd {
  flex: 1 1;
}
.statUs > p {
  display: flex;
  align-items: center;
  color: rgba(52, 71, 103, 0.7);

  font-weight: 600;
  font-size: 0.8vw;
}
.statUs {
  margin-top: 1vw;
}
.lined {
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
}
.boldL {
  background: var(--color-text);
  opacity: 0.9;
  height: 7px;
  border-radius: 0.5vw;
}
.nullL {
  background: var(--color-text);
  opacity: 0.5;
  border-radius: 0.5vw;
  height: 3px;
}
.statUs h4 {
  margin: 0.3vw 0;
  margin-bottom: 0.1vw;
  color: var(--color-text);
  font-size: 1.2vw;
}
.statUs > p > svg {
  background: linear-gradient(312.27deg, #0370f3 -5.24%, #03abf3 100%);
  border-radius: 0.3vw;
  fill: #fff;
  padding: 0.3vw;
  width: 1.5vw;
  margin-right: 0.3vw;
  height: 1.5vw;
}
.lastf b {
  font-weight: 700 !important;
}
.heartSold {
  width: 100%;
  aspect-ratio: 1/0.7;
  background: linear-gradient(
    0deg,
    rgb(104, 122, 151) 0%,
    rgb(44, 60, 87) 100%
  );
  padding: 1vw;
  padding-left: 1vw;
  border-radius: 0.7vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.valis {
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;

  color: #fff;
}
.valis p {
  font-size: 0.8vw;
  border-bottom: 1px solid #fff;
  padding-right: 0.3vw;
}
.heartSold > div {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
}
.line {
  background: #fff;
  width: 0.5vw;
  border-radius: 0.7vw;
}
/* This keeps child nodes hidden while the element loads */
:not(:defined) > * {
  display: none;
}
@keyframes hold {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
}
