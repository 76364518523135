.themEm {
  width: 2.5vw;
  height: 2.5vw;
  border-radius: 0.26041666666666663vw;
  border: 1px solid #ebebeb;
}
.flexTheme {
  display: flex;
  align-items: center;
  margin-bottom: 0.48125vw;
}
.thmCont {
}
.themesBlock {
  display: flex;
  align-items: center;
}
.flexTheme input {
  width: 13.020833333333334vw;
  padding: 0.5125vw;
  font-size: 0.8333333333333334vw;
  border-radius: 0.26041666666666663vw;
  margin: 0 1.0416666666666665vw;
  border: 0.052083333333333336vw solid #0370f3;
}
.themesPage {
  padding-left: 1.5vw;
  padding-top: 2vw;
  padding-right: 1.5vw;
}
.flexTheme p {
  width: 12vw;
  font-weight: 500;
  opacity: 0.7;
  font-size: 0.8vw;
  color: var(--color-text);
}
.themeHandler {
  font-size: 1.2vw;
  font-weight: 500;
  color: var(--color-text);
  cursor: pointer;
}
.themeTitle {
  font-weight: 600;
  font-size: 1.2vw;
  color: var(--color-text);

  margin-top: 0vw;
  margin-bottom: 1vw;
}
.swiperTheme {
  width: 70vw;
}
.themeHandler {
}
.colorsCont {
  display: grid;
  column-gap: 3vw;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}
.themesBlock .thmEm {
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow);
  background: #fff;
  border-radius: 1vw;
  margin-bottom: 1.5vw;
  padding: 1.5vw;
  width: fit-content;
}
.buttonsTheme {
  position: fixed;

  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
  padding: 1vw;

  width: auto;
}
.buttonsTheme div {
  background: var(--color-text);
  color: #fff;
  padding: 0.7vw 2.5vw;
  font-size: 0.8vw;
  letter-spacing: 0.03vw;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 5px;
}
.descTitle {
  font-size: 30px;
  color: var(--color-text);
  margin-bottom: 3px;
}
.descText {
  font-size: 16px;
  margin-bottom: 40px;
  color: var(--color-text);
  opacity: 0.7;
}
