.navbar {
  display: flex;
  flex-direction: column;

  position: relative;
  transition: 0.3s;
  top: 0;

  left: 0;

  z-index: 5;
  height: 100vh;
  width: 280px;
  max-height: 100vh;
  overflow-y: scroll;
  background-size: cover;
}
.navbar::-webkit-scrollbar {
  display: none;
}
.menu {
  position: relative;
  display: flex;
  align-items: center;
}
.homeActive {
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.07) 0rem 1.25rem 1.6875rem 0rem;
}
.homeActive p {
  font-weight: 600 !important;
}
.ofcs {
  max-width: 118px;
  width: 90px;
  margin-bottom: 50px;
  padding-left: 10px;
}
.navTo {
  display: flex;
  flex-direction: column;
  flex: 1 1;
  align-items: flex-start;

  padding: 40px 20px;
  padding-right: 20px;
  border-radius: 20px;
}

.llogo {
  display: flex;
  align-items: center;
  margin-left: 20px;
}
.llogo span {
  display: block;
  font-size: 12px;
  color: #3d3d3d;
  font-weight: 400;
  line-height: 12px;
  margin-left: 2px;
}
.llogo svg {
  width: 50px;
  margin-right: 10px;
  height: 50px;
  padding: 5px;
  fill: #fff;
  background: rgb(67, 67, 235);
  border-radius: 14px;
}
.ibRf {
  font-weight: 900;
  font-size: 36px;
  line-height: 36px;
  color: var(--color-text);
}
.ibRf p {
  color: var(--color-text);
}
.pagesUl ul {
  list-style: none;
  margin-left: 1.0416666666666665vw;
  margin-bottom: 1.0416666666666665vw;
}
.menuElemsNav {
  width: 100%;
}
.pagesUl li {
  display: flex;
  margin-bottom: 0.78125vw;
  font-size: 0.7291666666666666vw;
  cursor: pointer;
  align-items: center;
}

.pagesUl img {
  width: 1.5625vw;
  margin-right: 0.5208333333333333vw;
}
.navbar > img {
  width: 8.854166666666668vw;
}
.home {
  display: flex;
  align-items: center;
  color: #252525;
  font-weight: 500;
  width: 100%;
  padding: 10px 20px;
  border-radius: 14px;

  cursor: pointer;
}
.homeActive svg:first-child {
  background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  fill: #fff !important;
}
.home article {
  width: 100%;
}
.arrowDown {
  margin-left: auto;
}
.exist {
  margin-top: auto;
}
.arr {
  transform: rotate(180deg);
  width: 0.625vw !important;
  margin-left: 0.78125vw;
}
.lordI {
  margin-right: 0.5208333333333333vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home img {
  width: 2.083333333333333vw;
  margin-right: 0.5208333333333333vw;
}
.plus {
  background: #00c2ff;
  width: 1.5vw;
  height: 1.5vw;
  border-radius: 3px;
  margin-right: 0.5vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1vw;
  color: #fff;
  justify-content: center;
}
.voice_assistant {
  display: flex;
  flex-direction: column;
  margin-top: 21vw;
}
.voice_assistant input {
  padding: 0.26041666666666663vw;
  border-radius: 0.5208333333333333vw;
  font-size: 1vw;
  border: 0.052083333333333336vw solid black;
  margin-bottom: 1vw;
}
.voice_assistant > * {
  width: 100%;
}

.listen {
  background: #00c2ff;
  padding: 0.5208333333333333vw;
  margin-top: 1vw;
  text-align: center;
  border-radius: 0.26041666666666663vw;
  color: #fff;
}

.navRaz {
  flex-shrink: 0;
  border-top: 0px solid rgba(0, 0, 0, 0.12);
  border-right: 0px solid rgba(0, 0, 0, 0.12);
  border-left: 0px solid rgba(0, 0, 0, 0.12);
  background-color: transparent;
  height: 1px;
  margin: 40px 0;
  border-bottom: none;
  opacity: 0.25;
  width: 100%;
  background-image: linear-gradient(
    to right,
    rgba(52, 71, 103, 0),
    rgba(52, 71, 103, 0.5),
    rgba(52, 71, 103, 0)
  ) !important;
}
.home p {
  color: var(--color-text);
  opacity: 1;
  font-weight: 400;
  font-size: 16px;
}
.showShop a span {
  color: #0370f3;
  font-weight: bold;
}
.home {
}
.menu article {
  display: flex;
  align-items: center;
}
.showShop > div {
  padding: 5px 0;
}
.showShop {
  display: flex;
  flex-direction: column;

  overflow: hidden;
  width: 100%;
  max-height: 0;
  color: var(--color-text);
  font-size: 16px;
  right: 0;
  transition: 0.3s;
}

.showShop a span {
  color: var(--color-text);
  font-size: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  transition: 0s;
  justify-content: center;
}
.needHelp {
  background: linear-gradient(310deg, rgb(33, 82, 255), rgb(33, 212, 253));
  padding: 20px;
  width: 100%;
  border-radius: 14px;
  margin-top: 60px;
  margin-bottom: 30px;
}
.needHelp svg {
  background: #fff;
  fill: rgb(33, 212, 253);
  width: 40px;
  border-radius: 5px;
  height: 40px;
  padding: 6px;
}
.needHelp span {
  color: #fff;
  font-weight: 300;
  font-size: 14px;
}
.needHelp p {
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  margin-top: 0.5vw;
}
.write {
  width: 100%;
  background: #fff;
  padding: 14px 0;
  cursor: pointer;
  font-weight: 600;
  color: var(--color-text);
  border-radius: 0.5vw;
  margin-top: 20px;
  text-align: center;
}
.showShop a:hover span {
  font-size: 2vw;
}
.showShop a {
  display: flex;
  padding: 0 20px;
  align-items: center;
  color: var(--color-text);
  transition: 0s;

  width: 100%;
}

.menu {
}

.home svg {
  fill: var(--color-svg);
  box-shadow: rgba(20, 20, 20, 0.12) 0rem 0.25rem 0.375rem -0.0625rem,
    rgba(20, 20, 20, 0.07) 0rem 0.125rem 0.25rem -0.0625rem;
  width: 40px;
  transition: 0s;
  padding: 10px;
  border-radius: 10px;

  height: 40px;
  margin-right: 10px;
}
.arrowDown {
  box-shadow: none !important;
}
.home_links li {
  display: flex;
  align-items: center;
  margin-bottom: 1.3vw;
  cursor: pointer;
  transition: 0.5s;
  color: var(--color-text);
  font-size: 20px;
}

.home_links {
  list-style-type: none;
  font-size: 0.9vw;
  margin-left: 0.5vw;
}
.home_links li .circle {
  display: block;
  width: 0.6vw;
  height: 0.6vw;
  border-radius: 50%;
  background: #b0d7e3;
  margin-right: 1vw;
  cursor: pointer;
}
