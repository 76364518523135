.addUser {
  display: flex;
  background: #fff;
  border-radius: 20px;
  padding: 20px;
  box-shadow: var(--shadow);
  flex-direction: column;
}
.addUser input[type="text"] {
  padding: 10px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  border-radius: 7px;
  margin-bottom: 15px;
  margin-left: 0;
}

.addUser .saveButton {
  border-radius: 0.5vw;
  margin-top: 0;
  margin-left: 1vw;
  width: 12vw;
  padding: 0.5vw;
  align-items: center;
  display: flex;
  justify-content: center;
}
.workers h4 {
  margin-bottom: 0.5vw;
  font-size: 20px;
  margin-bottom: 20px;
  color: var(--color-text);
}
.workersBody {
  display: grid;
  grid-template-columns: 3fr 1fr;
  align-items: flex-start;
  column-gap: 20px;
}
.addWorker {
  padding: 10px;
  font-size: 16px;
  background: var(--color-text);
  color: #fff;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
}
.workersBody {
}
.workersfr {
  display: grid;
  grid-template-columns: 60px 50px 2fr 2fr 1fr 1fr 1.5fr 1fr;
  column-gap: 10px;
}
.kolot svg {
  margin-right: 10px;
}
.workersfr img {
  width: 40px;
  height: 40px;
  border: 1px solid #ebebeb;
  object-fit: cover;
  border-radius: 7px;
}
.workersTable {
  background: #fff;
  border-radius: 14px;
  box-shadow: var(--shadow);
  color: var(--color-text);
}
.workersfr > div {
  padding: 10px 0;
  color: var(--color-text);
  display: flex;
  align-items: center;
}
.workersTitle {
  text-transform: uppercase;
  font-size: 14px;
  opacity: 0.5;
  padding: 10px 0;
  font-weight: 600;
  border-bottom: 1px solid #ebebeb;
  color: var(--color-text);
}
.bubs {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0.7;
}
.bubs input {
  margin-left: 0;
  min-width: 18px;
  display: block;
  min-height: 18px;
}
.workersGrid {
  border-bottom: 1px solid #ebebeb;
}
