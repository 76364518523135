.selectMulElem {
  display: flex;
  align-items: center;
}
.selectMulElem input {
  margin-right: 1vw;
  min-width: 1.2vw;
  display: block;
  min-height: 1.2vw;
}
