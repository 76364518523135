.createPage {
  padding-top: 2vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
}
.blockInput p {
  color: gray;
  font-size: 0.8vw;
  margin-bottom: 0.3vw;
  margin-left: 1vw;
}
.formContainer {
  margin-bottom: 8vw;
}
.blockInput input {
  width: 22vw;
  padding: 1vw;
  border-radius: 1vw;
  font-size: 1vw;
  border: none;
  outline: none;
}
.blockInput {
  display: flex;
  flex-direction: column;

  margin-bottom: 1vw;
}
.saveButton {
  width: 22vw;
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
  padding: 1vw;
  text-align: center;
  color: #fff;
  border-radius: 1vw;
  margin-top: 1.5vw;
  font-size: 1vw;
  font-weight: 600;
  cursor: pointer;
}
