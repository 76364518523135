.header {
  padding: 15px 30px;
  display: flex;
  align-items: center;
  width: auto;

  top: 0;
  justify-content: flex-end;
}
.title_str {
  font-size: 2vw;
  font-weight: bold;
  margin-right: 2vw;
  width: 12vw;
}
.navigation {
  color: #bac1e0;
  font-size: 16px;
  margin-right: auto;
}
.navigation p {
  opacity: 0.5;
  margin-right: 5px;
}

.navigation {
  display: flex;
  align-items: center;
  color: var(--color-text);
  font-size: 16px;
}
.navigation svg {
  opacity: 0.5;
  width: 20px;
  height: 20px;
  margin-right: 5px;
}
.fromRight input {
  border: none;
  outline: none;
  padding-left: 10px;
  margin-left: 0;
  font-size: 16px;
}
.fromRight svg {
  fill: var(--color-text);
}
.toRight {
  display: flex;
  align-items: center;
}
.toRight > svg {
  margin: 0 10px;
  cursor: pointer;
  fill: var(--color-text);
}
.avt {
  width: 50px;
  margin-left: 10px;
  border: 2px solid var(--color-text);
  height: 50px;
  background: url(https://static.life.ru/publications/2023/10/31/702721848451.3439.png);
  background-size: cover;
  background-position: center;
  border-radius: 50%;
}
.fromRight {
  background: #fff;
  padding-left: 10px;
  overflow: hidden;
  border-radius: 14px;
  margin-right: 10px;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
}

.searchM {
  background: #fff;
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 15px 20px;
  margin: 0 2%;
}
.searchM input {
  border: none;
  outline: none;
  width: 17vw;
  font-size: 16px;
  background: none;
  border-radius: none;
  margin-top: 0;
}
.navigation span {
}
.header_button,
.dogovor {
  display: flex;
  align-items: center;
  font-size: 1vw;
  padding: 1vw;
  border-radius: 0.5vw;
  cursor: pointer;
  color: #496274;
}
.header_button p {
  margin-left: 0.3vw;
}
.header_button img {
  max-width: 1.5vw;
}
.dogovor {
  -webkit-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 21px 2px rgba(34, 60, 80, 0.15);
}

.header_button:hover {
  -webkit-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 23px 4px rgba(34, 60, 80, 0.2);
}
.sale {
  /* Frame 408496348 */

  /* Auto layout */
  font-size: 16px;
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
  box-shadow: 0px 6px 15px rgba(58, 95, 190, 0.45);
  border-radius: 10px;
  padding: 15px 20px;
  margin-right: 1.5%;
  color: #fff;
}
.sale img {
  margin-left: 15px;
  margin-right: 10px;
}
.profile {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-left: 15px;
  background: #fff;
  border: 3px solid #0370f3;
  cursor: pointer;
}
.profileName p {
  font-size: 16px;
  font-weight: 700;
}
.profileSt {
  display: flex;
  align-items: center;
  margin-left: 2%;
}
.profileName span {
  color: #0370f3;
  font-size: 12px;
  text-align: right;
  display: block;
}
