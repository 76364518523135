.gridEdit {
  display: grid;
  grid-template-columns: 35vw 2fr;
  column-gap: 20px;
  align-items: flex-start;
}
.gridEdit .lookAtInput {
  padding: 20px;
  border-radius: 20px;
  background: #fff;
  box-shadow: var(--shadow);
  color: var(--color-text);
}
.editProduct {
  position: relative;
}
.catsChoose h4 {
  text-transform: uppercase;
  font-size: 16px;
  margin-top: 15px;
  color: var(--color-text);
  font-weight: 600;
  margin-bottom: 10px;
}
.changeProduct {
  width: fit-content;
  margin-left: auto;
  right: 30px;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background: var(--color-text);
  color: #fff;
  cursor: pointer;
  top: 30px;
}
.descChange textarea {
  font-size: 16px !important;
  line-height: 20px;
  color: var(--color-text);
}
.mainImg img {
  border-radius: 14px;
  width: 100%;
  border: 1px solid #e0e0e0;
  aspect-ratio: 1/1;
  object-fit: cover;
}
.mainImg {
  display: flex;
  flex-direction: column;
}
.addNewPhoto svg {
  width: 34px;
  margin-right: 5px;
  height: 34px;
}

.addNewPhoto {
  width: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  background: var(--color-text);
  padding: 10px 20px;
  border-radius: 7px;
  color: #fff;
  text-align: center;
  margin-top: auto;
}
.trainImg::-webkit-scrollbar {
  display: none;
}
.trainImg {
  display: flex;
  width: 100%;
  overflow-x: auto;

  flex-direction: column;
  row-gap: 20px;
}
.trainImg img {
  width: 100%;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  border-radius: 9px;
  aspect-ratio: 1/1;
}
.editor {
  padding: 10px;
  line-height: 0.9em;
}
.bord {
  border: 1px solid #e0e0e0;
  border-radius: 14px;
  overflow: hidden;
  max-height: 300px;
}
.catI {
  background: #e0e0e0;
  border-radius: 200px;
  padding: 6px 10px;
  font-size: 16px;
  cursor: pointer;
  position: relative;
}
.catI:hover .deleteCat {
  display: flex;
}
.deleteCat {
  position: absolute;
  background: #fff;
  border-radius: 50%;
  box-shadow: var(--shadow);
  border: 1px solid var(--color-text);
  padding: 2px;
  display: none;
  width: 20px;
  height: 20px;

  align-items: center;
  justify-content: center;
  right: -8px;
  top: -8px;
}
.imageList {
  display: grid;
  grid-template-columns: 2.5fr 1fr;
  aspect-ratio: 1/0.85;

  column-gap: 20px;
}
.revC {
  margin-top: 15px;
}
.revC > div {
  margin-bottom: 10px;
  padding-bottom: 10px;
  display: flex;
  border-bottom: 1px solid #e0e0e0;
  align-items: center;
}
.gentChange label {
  display: flex;
}
.gentChange {
  position: absolute;
  left: 50%;
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(3px);
  border: 1px solid #e0e0e0;
  box-shadow: var(--shadow);
  border-radius: 7px;
  display: flex;
  opacity: 0;

  transition: 0.3s;
  align-items: center;
  top: 50%;
  transform: translate(-50%, -50%);
}
.catItem {
  display: flex;
  align-items: center;
  margin: 7px 0;
  padding: 7px 0;
  margin-top: 0;
  padding-top: 0;
}
.catItem:last-child {
  border-bottom: none;
}
.catItem input[type="checkbox"] {
  width: 16px;
  height: 16px;
  display: block;
  margin-left: 0;
}
.catsChoose input[type="text"] {
  padding: 10px;
  width: 100%;
  margin-bottom: 30px;
  font-size: 16px;
  border: 1px solid #e0e0e0;
  margin-left: 0;
}
.catItem p {
  font-size: 16px;
  color: var(--color-text);
  margin-left: 10px;
  opacity: 0.7;
  width: 200px;
}
.choosing {
  flex: 1 1;
  overflow-y: auto;
}
.catsChoose h3 {
  color: var(--color-text);
  margin-bottom: 20px;
  font-size: 18px;
  display: flex;
  align-items: center;
}
.goHe {
  position: absolute;
  right: 20px;
  fill: var(--color-text) !important
  ;
  cursor: pointer;
  top: 20px;
}
.catsChoose svg {
  margin-right: 5px;
}
.catsChoose {
  position: fixed;
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  border-left: 1px solid #e0e0e0;
  transition: 0.3s;
  box-shadow: var(--shadow);
  height: 100vh;
  z-index: 50;
  padding: 40px;
  padding-top: 60px;
  background: #fff;
}
.gentChange svg:hover {
  transform: scale(1.1);
}
.gentChange svg {
  fill: var(--color-text);
  width: 2vw;
  transition: 0.1s;
  cursor: pointer;
  height: 2vw;
  margin: 10px;
}
.imgChange {
  position: relative;
}
.imgChange:hover .gentChange {
  opacity: 1;
}
.revC > div:last-child {
  border-bottom: 0;
  margin-bottom: 0;
  border-bottom: none;
}
.imgChange input {
  position: absolute;
  right: 2000px;
}
.revC > div article {
  margin-right: 20px;
  flex: 1 1;
}
.revC > div article div {
  font-size: 14px;
  color: var(--color-text);
}
.revC > div article span {
  color: var(--color-text);
  font-size: 16px;
  margin-bottom: 5px;
  display: block;
  font-weight: 600;
}
.revC > div img {
  width: 70px;
  object-fit: cover;
  margin-right: 15px;
  aspect-ratio: 1/1;
  border-radius: 7px;
}
.addCat {
  background: var(--color-text);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 200px;
  cursor: pointer;
  width: 46px;
  font-size: 16px;
  height: 28px;
}
.trainImg::-webkit-scrollbar {
  height: 8px;
}
.descChange textarea {
  resize: none;
}
.deleteCat svg {
  width: 100%;
  height: 100%;
}
.CategoryItems {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  align-items: flex-start;

  max-height: 160px;
  overflow: auto;
  padding: 10px;
  border: 1px solid #e0e0e0;
  border-radius: 7px;
}
.lookAtInput {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 1vw;
  row-gap: 1vw;
}
.tLsInput input {
  border: 1px solid #e0e0e0;
  padding: 10px;
  margin-left: 0;
  outline: none;
  color: var(--color-text);
  font-size: 16px;
  width: 100%;
}
.tLsInput p {
  font-size: 14px;
  font-weight: 600;
  opacity: 0.7;
  margin-bottom: 3px;
}
.descChange {
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  color: var(--color-text);
  box-shadow: var(--shadow);
  margin-top: 20px;
}
.descChange textarea {
  border: 1px solid #e0e0e0;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border-radius: 5px;
}
.Cats {
  grid-column: span 2;
}
