.addOrder {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr;
  column-gap: 30px;
  height: 100%;
  align-items: flex-start;
}
.addOrder > div {
  background: #fff;
  border-radius: 1vw;
  color: var(--color-text);
  padding: 30px;
  box-shadow: var(--shadow);
}
.ordD p {
  margin-bottom: 15px;
  font-weight: 600;
}
.ordD span {
  opacity: 0.7;
  font-weight: 400;
}
.vzZaz {
  background: var(--color-text);
  padding: 15px;
  color: #fff;
  text-align: center;
  border-radius: 7px;
  margin-top: 30px;
}
.ordD h3 {
  margin-bottom: 20px;
}
.mainBodyOrder h3 {
  font-size: 20px;
  margin-bottom: 15px;
}
.additionsOrder .checkImg > img {
  width: 80px;
  border-radius: 50%;
  margin-right: 15px;
}
.writeTo,
.vz {
  padding: 15px;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
  margin-top: 15px;
}
.mainBodyOrder > p {
  margin-bottom: 10px;
  font-weight: 500;
}
.mainBodyOrder > p span {
  font-weight: 400;
  opacity: 0.7;
}
.vz {
  border: 1px solid var(--color-text);
}
.vzZa {
  position: absolute;
  right: 20px;
  top: 40px;
  padding: 10px 15px;
  cursor: pointer;
  width: 150px;
  background: var(--color-text);
  color: #fff;
  border-radius: 7px;
  text-align: center;
}
.mainBodyOrder {
  position: relative;
}
.writeTo {
  color: #fff;
  background: var(--color-text);
  margin-top: 30px;
}
.additionsOrder .checkImg span {
  opacity: 0.7;
  font-weight: 400;
  font-size: 14px;
}
.addOrder .navRaz {
  margin: 10px 0;
}
.checkImg {
  display: flex;
  position: relative;

  align-items: center;

  font-weight: 600;

  border-radius: 14px 14px;
}
.additionsOrder > p {
  margin-top: 15px;
  color: var(--color-text);
  font-weight: 500;
}
.additionsOrder > p span {
  opacity: 0.7;
  font-weight: 400;
}
.orderCard {
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ebebeb;
}
.mainBodyOrder .navRaz {
  margin: 10px 0;
}
.mainBodyOrder article {
  font-weight: 600;
  font-size: 20px;
  color: var(--color-text);
}
.orderCardPrice {
  margin-left: auto;
  font-weight: 600;
}
.lookAtOrder {
}
.mainBodyOrder > p {
  opacity: 1;
}
.orderCardTitle p {
  font-weight: 600;
  margin-bottom: 10px;
}
.orderCard {
  display: flex;
  align-items: center;
}
.orderCard > img {
  width: 100px;
  margin-right: 20px;
  border-radius: 7px;
  aspect-ratio: 1/1;
}
