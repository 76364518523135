.tovarEm {
  padding: 0.5vw 1vw;
  background: #fff;
  border-radius: 0.5vw;
  margin-bottom: 0.4vw;
  display: flex;
  align-items: center;
  transition: 0.3s;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.1);
  border: 2px solid #fff;
  font-size: 0.8vw;
}
.sizes {
  display: flex;
  margin-top: 0.5vw;
}
.sizeColumn div {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 8vw;
  margin-bottom: 0.8vw;
}
.sizeColumn input {
  border: 0;
  width: 1vw !important;
  margin-bottom: 0 !important;
  height: 1vw;
  margin-right: 0.5vw;
} /* Frame 408496712 */

.hotsRed {
  display: flex;
  align-items: center;
  margin-bottom: 1vw;
}
.colHot {
  margin-left: 0.5vw;
  width: 3vw !important;
}
.loadImg input {
  display: none;
}
.loadImg {
  min-width: 2vw;
  width: 2vw;
  cursor: pointer;
  height: 2vw;
  padding: 0.3vw;
  border: 1px solid #0370f3;
  border-radius: 5px;
  margin-right: 0.5vw;
}
.loadImg img {
  width: 100%;
}
.hotsEms {
  margin-bottom: 1vw;
} /* Frame 408496712 */

.hotCheck {
  width: fit-content;
  border-radius: 5px;
  color: #fff;
  padding: 0.5vw;
  padding-right: 0.8vw;
  margin-bottom: 0.5vw;
  display: flex;
  font-size: 0.8vw;
  align-items: center;
}
.hotCheck img {
  width: 0.8vw;
  margin-right: 0.5vw;
}
.addHot {
  color: white;
  background: #0370f3;
  border-radius: 5px;
  padding: 0.65vw 1vw;
  margin-left: 0.5vw;
  cursor: pointer;
  font-size: 0.7vw;
}
.hotsRed input {
  margin-bottom: 0 !important;
}
.instruments label {
  font-size: 0.8vw;
  margin-left: 0.5vw;
}
.instruments #vis {
  width: 1vw;
  height: 1vw;
}
.laster {
  display: flex;
  align-items: center;
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.laster p {
  font-weight: bold;
  font-size: 1vw;
}
.laster input {
  width: 1vw !important;
  height: 1vw;
  margin-right: 0.5vw;
  margin-bottom: 0 !important;
}
.sizeColumn p {
  font-size: 1vw;
}
.tovarEm:hover {
  background: rgb(245, 245, 245);
  border: 2px solid rgb(245, 245, 245);
}
.mainTovar {
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);

  color: #fff;
  font-weight: bold;
  width: 98%;
  margin-left: 0.5vw;
}

.mainTovar:hover {
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
}
.mainTovar img {
  opacity: 0;
}
.instruments input {
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.1);
  font-size: 0.8vw;
}
.closeMe {
  background: #0370f3;
  border: 2px solid #0370f3;
  padding: 0.5vw 1vw;
  color: #fff;
  width: 8vw;
  border-radius: 0.5vw;
  text-align: center;
  cursor: pointer;
}
.saveTovar {
  border: 2px solid #0370f3;
  padding: 0.5vw 1vw;
  color: black;
  width: 8vw;
  text-align: center;
  border-radius: 0.5vw;
  cursor: pointer;
}
.saveTovar:hover,
.closeMe:hover {
  transform: translateY(-3px);
}

.imagesDiv {
  display: flex;
  align-items: center;
  padding: 10px 0;
  border-bottom: 1px solid rgb(234, 234, 234);
}
.imagesDiv input {
  border: none;
  width: 12vw;
  display: none;
}
.imagesDiv label {
  padding: 10px;
  font-size: 0.8vw;
  display: flex;
  color: #fff;
  background: #0370f3;
  border-radius: 0.3vw;
  cursor: pointer;
}
.imagesDiv label:hover {
  background: #034ca4;
}
.newImg {
  margin-top: 1vw;
}
.newImg label {
  width: 100%;
  margin: 10px 0;
  display: block;
}
.imagesDiv img {
  width: 80px;
  height: 80px;
  object-fit: cover;

  border-radius: 0.5vw;
  margin-right: 20px;
}
.red {
  font-size: 1.5vw;
  font-weight: bold;
  text-transform: uppercase;
  color: #0370f3;
  margin-bottom: 2vw;
}
.changeText {
  height: 80vh;
  overflow-y: scroll;
  padding-top: 1vw;
}
.changeText::-webkit-scrollbar {
  width: 0;
}
.popUpTovar {
  position: fixed;
  z-index: 2000;
  padding: 1.5vw 1.5vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.5);
  height: 100vh;
  left: 0;
  top: 0;
  background: #fff;
  width: 450px;
  animation: slide 0.5s forwards;
}
.relate {
  position: relative;
}
.relate:hover .searchCat {
  display: block;
}
.deletTovar {
  color: red;

  width: 1vw;
  height: 1vw;
  font-size: 0.8vw;
  border-radius: 0.3vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.deletTovar:hover {
  background: #9de7fd;
}
.newTovar {
  padding: 0.5vw 1vw;
  margin-right: 1vw;
  width: fit-content;
  background: linear-gradient(90deg, #0370f3 -3.25%, #03abf3 128.75%);
  border-radius: 0.5vw;
  color: #fff;
  margin-left: 1vw;
  cursor: pointer;
}
.newTovar:hover {
  filter: brightness(1.3);
}
.instruments {
  display: flex;
  align-items: center;
}
.searchCat div {
  cursor: pointer;
  margin: 0.3vw 0;
}
.searchCat div:hover {
  transform: translateX(3px);
}
.searchCat {
  position: absolute;
  display: none;
  z-index: 3;
  padding: 1vw;
  border: 1px solid #0370f3;
  border-radius: 0.5vw;
  top: 4vw;
  left: 0;
  width: 17vw;
  background: #fff;
}
.tovarButtons {
  display: flex;
  position: absolute;
  left: 0;
  font-size: 0.8vw;
  border-top: 2px solid #0370f3;
  bottom: 0;
  padding: 1vw 1.5vw;
  background: #fff;

  justify-content: space-between;
  width: 100%;
}
.instruments {
  margin-bottom: 30px;
  padding-left: 0.5vw;
}
.type {
  width: 9vw;

  border-right: 1px solid rgb(230, 230, 231);
  padding-left: 1vw;
  padding-right: 1vw;
}
.tovarList {
  max-height: 73vh;
  overflow-y: scroll;
  padding-bottom: 30px;
  padding-right: 1vw;
  padding-left: 0.5vw;
  padding-top: 0.5vw;
}
.delImg {
  padding: 10px;

  border: 1px solid rgba(0, 0, 0, 0.23);
  border-radius: 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-left: 20px;
  cursor: pointer;
}
.delImg:hover {
  background: #ededed;
}
.id {
  font-weight: bold;
  width: 2.5vw;
  font-size: 1vw;
}
.tovarEm img {
  width: 3vw;
  height: 3vw;
  margin-right: 1vw;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #0370f3;
}
.tovarTitle {
  width: 15vw;
}
.price {
  width: 7vw;
  font-weight: bold;
  border-left: 1px solid rgb(230, 230, 231);
  border-right: 1px solid rgb(230, 230, 231);
  padding-left: 1vw;
  padding-right: 1vw;
}
@keyframes slide {
  0% {
    transform: translateX(-25vw);
  }
  100% {
    transform: translateX(0);
  }
}
