.pagesGrid {
  display: grid;
  column-gap: 1vw;
  row-gap: 1vw;
  grid-template-columns: 1fr;
}
.pagesGrid > div {
  background: #fff;
  border-radius: 1vw;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.5vw;
  padding: 2vw;
  font-weight: 600;
  position: relative;
  cursor: pointer;
  border: 1px solid transparent;
  transition: 0.3s;
}
.pageList .gridTitle {
  margin-bottom: 0;
}
.pageList {
  padding-top: 2vw;
}
.statPages {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  column-gap: 1vw;
}
.pageList .svodIcon {
  background: var(--color-text);
}
.searchPageList h3 {
  font-weight: 500;
  color: var(--color-text);
  font-size: 1.2vw;
  opacity: 0.7;
}

.statPages > div {
  display: flex;
  background: #fff;
  color: var(--color-text);
  border-radius: 0.7vw;
  padding: 1vw;
  box-shadow: var(--shadow);
  justify-content: space-between;
}
.pageList .gridRow {
  border-bottom: 1px solid #ebebeb;
  padding: 1vw;
}
.pagesGrid > div:hover {
  border: 1px solid #0370f3;
}
.searchPageList {
  display: flex;
  margin-top: 1.5vw;
  justify-content: space-between;
  align-items: center;
}
.searchPageList input {
  width: 12vw;
  padding: 0.7vw;
  margin-right: auto;
  font-size: 0.8vw;
  border-radius: 0.5vw;
  background: none;
  border: 1px solid #dedede;
  outline: none;
}
.searchPageList {
  margin-bottom: 1vw;
}
.searchPageList h2 {
  font-size: 2vw;
}
.pagesGrid > div svg {
  width: 3vw;
  fill: #0370f3;
  height: 3vw;
  margin-bottom: 10px;
}
.pagesGrid > div:hover .deletePage {
  transform: scale(1);
}
.addNewPage {
  background: #0370f3 !important;
  color: #fff;
}
.addNewPage:hover {
  text-decoration: underline;
}
.addNewPage svg {
  fill: #fff !important;
}
.deletePage {
  position: absolute;
  background: #0370f3;
  fill: #fff !important;
  right: -0.5vw;
  top: -0.5vw;
  transform: scale(0);
  border-radius: 50%;
  width: 2vw !important;
  transition: 0.3s !important;
  height: 2vw !important;
}
