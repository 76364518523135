.mainEditBodyRight {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: flex-end;
  margin-bottom: 2vw;
}
.mainEditBodyRight h3 {
  font-size: 1vw;
  margin-bottom: 0.5vw;
}
.mainEditBodyRight .redEdit > * {
  width: 100%;
}
.mainEditBodyRight .redEdit input {
  font-size: 0.8vw;
  padding: 0.5vw;
  border: 1px solid rgba(62, 149, 255, 1);
  margin: 1vw 0;
  border-radius: 0.5vw;
}

.mainEditBodyRight .redEdit video {
}
.mainEditBody {
  display: flex;

  flex-direction: column-reverse;
  margin-top: 0vw;
}
.mainEditBodyRight .redEdit {
  margin-top: 1vw;
}
.mainEditBodyRight .redEdit h4 {
  font-size: 1vw;
}
.actRed {
  background: #fff;
  padding: 1vw;
  margin-top: 1vw;
  border-radius: 0.5vw;
}
.postProducts > p {
  font-size: 1vw;
  margin: 1vw 0;
}
.MainPageEditor textarea {
  width: 20vw;
  height: 10vw;
  padding: 0.5vw;
  transition: 0s;
  border: 1px solid #3e95ff;
  border-radius: 0.5vw;
  min-width: 20vw;
  max-width: 20vw;
  margin-top: 1vw;
}
.absol {
  position: fixed;
  z-index: 80;
  bottom: 1vw;
  right: 1vw;
}
.mainEditBodyRight h1 {
  text-transform: uppercase;
  font-size: 1.5vw;
  color: black;
  transition: 0s;
  cursor: pointer;
  margin-left: 1vw;
}
.mainEditBodyRight h1 span {
  transition: 0.1s;
}
.saveChanges {
  background: #3e95ff;
  width: 20vw;
  padding: 1vw;
  border-radius: 0.5vw;
  text-align: center;
  color: #fff;
  font-size: 0.9vw;
  margin-top: 1vw;
  cursor: pointer;
}
.saveChanges:hover {
  background: #035dca;
}
.prodType {
  display: flex;
  flex-wrap: wrap;
  flex: 1 1;
  row-gap: 1vw;
  justify-content: space-between;
}
.prodType div {
  width: 23%;
  background: #fff;
  padding: 1vw;
  -webkit-box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  border-radius: 1vw;
}
.saveChange {
  background: #0370f3;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  color: #fff;
  font-size: 0.9vw;
  cursor: pointer;
}
.htmlButtons {
  display: flex;
  position: absolute;
  bottom: 1vw;
  right: 1vw;
}
.fullScreen {
  border: 1px solid #0370f3;
  padding: 0.5vw 1vw;
  border-radius: 0.5vw;
  margin-right: 1vw;
  font-size: 0.9vw;
  cursor: pointer;
}
.prodType div img {
  width: 100%;
  border-radius: 0.7vw;
}
.prodType div p {
  color: black;
  margin-top: 0.5vw;
  text-align: center;
}
.ide * {
  transition: 0s;
}
.ide > div > div {
  transition: 0.5s !important;
}
.ide {
  position: fixed;
  left: 13vw;
  bottom: 0;
  width: 87vw;
  border-top: 3px solid #3e95ff;
  background: #fff;

  z-index: 6;
}
.mainEditBodyLeft > .customHtmlBlock > p {
  color: gray;
  font-style: italic;
  font-size: 0.8vw;
  margin-bottom: 0.3vw;
  margin-left: 1vw;
}
.mainEditBodyLeft > div {
  margin-bottom: 2vw;
}
.mainEditBodyLeft > .customHtmlBlock > div {
  margin-bottom: 1vw;
  background: #fff;
  border-radius: 1vw;
  -webkit-box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  box-shadow: -1px 0px 25px -11px rgba(34, 60, 80, 0.2);
  padding: 1vw;
  cursor: pointer;
  border: 3px solid #fff;
}
.errMsg {
  color: red;
  margin-top: 1vw;
  font-size: 0.8vw !important;
}
.mainEditBodyLeft > div:hover {
  transform: translateY(-5px);
}
.ide h4 {
  margin: 1vw 0;
  margin-left: 1vw;
}
.mainEditBodyLeft {
  padding-right: 1.5vw;
}
.mainEditBodyLeft h3 {
  margin-bottom: 1vw;
  text-transform: uppercase;
}
.beginAdd {
  margin-top: 1vw;
  display: flex;
  align-items: center;
}
.beginAdd label {
  font-weight: 600;
  font-size: 0.9vw;
  margin-left: 0.5vw;
}
.customHtml {
  margin-top: 1vw;
}
