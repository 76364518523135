.formActions input {
  padding: 1vw;
  border: 1px solid black;
  border-radius: 1vw;
  margin-bottom: 1vw;
  width: 100%;
  font-size: 0.8vw;
}
.formActions input[type="file"] {
  height: 0;
}
.popChange {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 222;
}
.popBodyChange {
  background: #fff;
  padding: 2vw;
  border-radius: 1vw;
}
