.productsBody {
  background: #fff;
  border-radius: 1vw;
  box-shadow: var(--shadow);
  margin-top: 1vw;
}
.prodBodyTitle {
  padding: 1.5vw;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-bottom: 1vw;
}
input {
  outline: none;
}
.leftProdTitle h3 {
  color: var(--color-text);
  font-weight: 500;
  font-size: 1.2vw;
}
.leftProdTitle p {
  font-size: 0.8vw;
  color: var(--color-text);
  opacity: 0.7;
  margin-top: 0.2vw;
}
.rightProdTitle {
  display: flex;
}
.opacButton {
  display: flex;
  align-items: center;
  border: 1px solid #2a98f9;
  border-radius: 0.5vw;
  margin-left: 0.5vw;
  padding: 0 2vw;
  font-weight: 600;
  color: #2a98f9;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.7vw;
}
.opacButton:hover {
  text-decoration: underline;
}
.availibity {
  background: rgb(205, 245, 155);
  color: rgb(103, 177, 8);
  padding: 5px 0.5vw;
  border-radius: 0.2vw;
  font-size: 0.6vw;
  text-transform: uppercase;
}
.productsSearchPanel input {
  font-size: 0.8vw;
  padding: 0.5vw;
  border: 1px solid #ebebeb;
}
.gridTitle svg {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}
.gridTitle div {
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.productsBody .gridTitle div:hover {
  opacity: 1;
}
.iconsProduct svg {
  margin-right: 0.6vw;
  opacity: 0.7;
  width: 22px;
  height: 22px;
}
.productImage img {
  width: 3vw;
  height: 3vw;
  border-radius: 0.3vw;
  object-fit: cover;
}
.productsBody .userName {
  font-weight: 400;
  opacity: 0.8;
}
.productsBody .gridRow {
  border-bottom: 1px solid #ebebeb;
}
.productsBody .gridTitle {
  margin-bottom: 0;
}
