.redEdit {
  margin-top: 1vw;
}
.redEdit h4 {
  margin-bottom: 4px;
  opacity: 0.7;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-text);
}

.profilePage input {
  padding: 16px;
  font-size: 16px;
  border: 1px solid #f0f0f0;
  border-radius: 14px;

  margin-left: 0;
  outline: none;
  width: 100%;
}

.redEdit img {
  border-radius: 0.5vw;
  margin-top: 1vw;
}
.profilePage {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 2vw;
}
.flexs input {
  width: 600px;
  margin-top: 20px;
  display: block;
}
.snow {
  display: flex;
  flex-direction: column;
}
.showLogo {
  margin: 30px 0;
}
.csss {
  width: 100%;
  border: 1px solid var(--color-text);
  border-radius: 14px;
  font-size: 16px;
  background: none;
  color: var(--color-text);
  padding: 14px;
  cursor: pointer;
}
.showLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1vw;
}
.showLogo img {
  width: 60%;
}
.pokaz {
  padding: 0.5vw 2vw;
  color: #fff;
  background: #0370f3;
  border-radius: 0.5vw;
  cursor: pointer;
  font-size: 1vw;
  width: fit-content;
}
.lols {
  width: 30vw;
}
.flexs .guZdik {
  width: 30vw !important;
  min-width: 30vw !important;
}
.profilePage {
  padding-bottom: 2vw;
}
.profilePage .guZdik {
  width: 100% !important;
  max-width: 100% !important;
}
.save {
  background: var(--color-text);
  padding: 20px 14px;

  text-align: center;
  font-size: 16px;
  border-radius: 0.7vw;
  color: #fff;
  cursor: pointer;
  width: 100%;
  margin-left: auto;
  margin-top: 40px;
  transition: 0.3s;
}

.profilePage video {
  width: 30vw;
  margin-top: 1vw;
  border-radius: 0.5vw;
  margin-right: 2vw;
}
