.usersBlock {
  background: #fff;
  margin-bottom: 10px;
  padding: 0.8vw 1vw;
  border-radius: 0.7vw;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.1);
}
.userAvatar {
  width: 2.3vw;
  height: 2.3vw;
  object-fit: cover;
  border-radius: 0.7vw;
  border: 1px solid #ececec;
}
.userId {
  font-size: 1.5vw;
  margin-right: 0.5vw;
  width: 2vw;
  display: flex;
  align-items: center;
  text-align: center;
  font-weight: 600;
  color: #0370f3;
}
.userPhone {
  font-size: 0.8vw;

  color: var(--color-text);
  opacity: 0.7;
}
.userName {
  font-size: 16px;
  color: var(--color-text);
  opacity: 0.7;
  font-weight: 500;
}
.gridTable {
  display: grid;
  grid-template-columns: 1.5vw 2.5vw 2fr 2fr 2fr 2fr 2fr 3vw 1.5vw 1.5vw;
  column-gap: 1vw;
}
.dayPlan {
  padding: 1vw;
  border-radius: 1vw;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}
.gridRow svg {
  transition: 0.3s;
}
.gridRow svg:hover {
  transform: scale(1.1);
}
.gridTable input[type="checkbox"] {
  width: 18px;
  height: 18px;

  display: block;
  margin: 0 auto;
  opacity: 0.7;
}
.progB {
  width: 100%;
  height: 6px;
  background: #ececec;
  margin-top: 0.5vw;
  border-radius: 0.5vw;
}
.progB .load {
  background: linear-gradient(
    259deg,
    rgb(238, 137, 54) 0%,
    rgb(237, 3, 15) 100%
  );
  height: 100%;
  border-radius: 0.5vw;
}
.dayPlan {
  display: flex;
  flex-direction: column;
}
.txtAndButton {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: auto;
}
.progressTitle {
  margin-top: 1vw;
}
.dayPlan h3 {
  font-size: 0.9vw;
  color: var(--color-text);
  font-weight: 500;
  margin-bottom: 0.5vw;
}
.txtAndButton p {
  color: var(--color-text);
  font-size: 0.8vw;
  opacity: 0.9;
  font-weight: 400;
  line-height: 1.2vw;
}
.progressTitle p {
  display: flex;
  justify-content: space-between;
  color: var(--color-text);
  opacity: 0.7;
  font-weight: 500;
  font-size: 0.8vw;
}
.newplan {
  padding: 0.7vw 1.2vw;
  border-radius: 0.5vw;
  color: #fff;
  cursor: pointer;
  font-weight: 500;
  font-size: 0.7vw;
  text-transform: uppercase;
  background: linear-gradient(
    310deg,
    rgb(104, 122, 151) 0%,
    rgb(44, 60, 87) 100%
  );
}
.usersStat {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1.5vw;
  row-gap: 1.5vw;
}
.usersStat > div {
  background: rgb(133, 153, 187);
  background: linear-gradient(
    310deg,
    rgb(118, 137, 168) 0%,
    rgb(40, 55, 80) 100%
  );
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  color: #fff;
  border-radius: 0.7vw;
  padding: 1vw;
  position: relative;
}
.dotsMore {
  position: absolute;
  right: 1vw;
  font-size: 1.2vw !important;
  top: 1vw;
}
.usersStatCount {
  font-weight: 600;
  font-size: 1.2vw;
}
.userStatIcon {
  background: #fff;
  width: 2.5vw;
  height: 2.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.5vw;
  margin-bottom: 0.8vw;
}
.userStatIcon svg {
  width: 50%;
  height: 50%;
  fill: var(--color-text);
}
.gridRow > div {
  display: flex;
  align-items: center;
}
.usersTitleStat {
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 0.8vw;
  font-weight: 400;
}
.usersTitleStat span {
  font-weight: 600;
}
.panelUp svg {
  width: 1.2vw;
  height: 1.2vw;
}
.shadowWalk:hover .panelUp {
  display: flex;
}
.panelUp:hover {
  text-decoration: underline;
}
.panelUp {
  position: absolute;
  top: 1vw;
  cursor: pointer;

  right: 1vw;

  border-radius: 0.5vw;
  display: none;
  align-items: center;
  font-size: 0.8vw;
  color: var(--color-text);
  animation: open 0.3s;
}
@keyframes open {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.openPanel {
  padding: 5px;
  background: none;
  border: none;
  margin-bottom: 10px;
  cursor: pointer;
  width: fit-content;
  margin-left: auto;
  color: var(--color-text);
  display: flex;
  align-items: center;
  font-size: 0.8vw;
}
.openPanel svg {
  transform: rotate(180deg);
  width: 1.2vw;
  height: 1.2vw;
}
.openPanel:hover {
  text-decoration: underline;
}
.shadowWalk {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1vw;
  position: relative;
  transition: 0.3s;

  margin: 1.5vw 0;
}
.usersTable {
  background: #fff;

  border-radius: 1vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}
.gridTitle {
  padding: 1vw;
  margin-bottom: 0.5vw;
  text-transform: uppercase;
  font-weight: 600;
  color: var(--color-text);
  font-size: 14px;
  align-items: center;

  border-bottom: 1px solid #ececec;
}
.gridTitle div {
  opacity: 0.5;
}
.gridRow {
  padding: 0.5vw 1vw;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
}
.gridRow > div {
  font-size: 16px;
  color: var(--color-text);
  font-weight: 500;
}
.gridRow > span {
  font-size: 0.8vw;
  color: var(--color-text);
  opacity: 0.7;
  font-weight: 500;
}
.gridRow:hover {
  background: #ececec;
}
