.groupsBody {
}
.groupItem {
  background: #fff;
  margin-bottom: 1vw;
  border-radius: 0.7vw;
  padding: 1vw;
  box-shadow: var(--shadow);
}
.groupsBody > p {
  font-size: 0.8vw;
  color: var(--color-text);
  opacity: 0.7;
  line-height: 1vw;
  margin-top: 0.2vw;
  margin-bottom: 1vw;
}
.groupActions {
  margin-left: auto;
  display: flex;
}
.groupItem .availibity {
  margin-left: 0.5vw;
}
.groupActions svg {
  margin: 0 0.3vw;
  cursor: pointer;
}
.groupItem h3 {
  color: var(--color-text);
  font-weight: 600;
  display: flex;
  align-items: center;
  margin-bottom: 0.7vw;
  margin-top: 0.5vw;
}
.groupsBody h2 {
  font-size: 1.5vw;
  font-weight: 600;
  color: var(--color-text);
}
.groupItem h3 svg {
  margin-right: 5px;
}
.categoryPage {
  display: grid;
  column-gap: 2vw;
  padding-top: 2vw;
  grid-template-columns: 3.5fr 1fr;
  align-items: flex-start;
}
.groupRight h3 {
  font-size: 1.2vw;
  color: var(--color-text);
  margin-top: 1vw;
  margin-bottom: 1vw;
}
.groupRight > p {
  font-size: 0.7vw;
  color: var(--color-text);
  opacity: 0.7;
  font-weight: 600;
  margin-top: 0.8vw;
  margin-bottom: 0.2vw;
}
.groupRight input {
  margin-left: 0;
  font-size: 0.8vw;
  width: 100%;
  border: 1px solid #ebebeb;
  padding: 0.7vw;
}
.BarStat {
  margin-top: 1vw;
  background: #fff;
  box-shadow: var(--shadow);
  padding: 1vw;
  border-radius: 1vw;
}
.yes {
  border: 1px solid #ebebeb;
  width: 1.2vw;
  height: 1.2vw;
  border-radius: 0.3vw;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: pointer;
  margin-right: 0.3vw;
}
.yes svg {
  background: var(--color-text);
  fill: #fff;
  padding: 0.1vw;
  width: 100%;
  height: 100%;
}
.groupRight > div {
  margin-top: 0.5vw;
  align-items: center;
  font-size: 0.8vw;
  color: var(--color-text);
  display: flex;
}
.groupRight span {
  font-weight: 500;
}
.groupRight .newplan {
  color: #fff;
  justify-content: center;
}
.groupRight > span {
  font-size: 0.8vw;
  margin-bottom: 1vw;
  color: var(--color-text);
  opacity: 0.7;
  font-weight: 400;
  margin-top: -0.7vw;
  display: block;
}
.groupRight {
  background: #fff;
  box-shadow: var(--shadow);
  padding: 1vw;
  border-radius: 1vw;
  margin-bottom: 2vw;
}
.categoryItem {
  border: 1px solid #ebebeb;
  padding: 10px;
  border-radius: 300px;
  font-size: 16px;
  cursor: pointer;
  width: fit-content;
}
.categoryItem:hover {
  border: 1px solid var(--color-text);
}
.catsItems {
  display: flex;
  flex-wrap: wrap;
  column-gap: 0.5vw;
  row-gap: 0.5vw;
}
.categoryItem b {
  width: 8px;
  height: 8px;
  display: block;
  margin-right: 5px;
  border-radius: 50%;
}
.categoryItem {
  display: flex;
  align-items: center;
}
.addNewCat {
  border: 1px solid var(--color-text);
  padding: 10px;
  border-radius: 300px;
  font-size: 16px;

  color: var(--color-text);
  width: 60px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
}
.addNewCategory {
  position: fixed;
  z-index: 20;
  right: 0;
  top: 0;
  transition: 0.3s;
  background: #fff;
  height: 100vh;
  display: flex;
  flex-direction: column;

  box-shadow: var(--shadow);
  border-left: 1px solid #ebebeb;
  padding: 40px;
}
.addNewCategory input[type="text"] {
  padding: 10px;
  border: 1px solid #ebebeb;
  font-size: 16px;
  margin-left: 0;
  width: 250px;
}
.addNewCategory h3 {
  margin-bottom: 5px;
  color: var(--color-text);
  font-size: 20px;
}
.opc {
  display: flex;
  align-items: center;
  margin-top: 10px;
  font-size: 14px;
  color: var(--color-text);
}
.opc label {
}
.editCat svg {
  width: 16px;
  height: 16px;
  margin-left: 5px;
  fill: var(--color-text);
}
.editCat svg:hover {
  transform: scale(1.1);
}

.editCat {
  display: flex;
  align-items: center;
  max-width: 0;
  overflow: hidden;
  transition: 0.3s;
}

.editCat svg:first-child {
  margin-left: 10px;
}
.opc input {
  margin-left: 0;
  margin-right: 5px;
  width: 16px;
  height: 16px;
}
.addCats {
  margin-top: 20px;
  background: var(--color-text);
  padding: 10px;
  color: #fff;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
  border-radius: 7px;
  cursor: pointer;
}
.closeCats {
  margin-top: 10px;
  border: 1px solid var(--color-text);
  padding: 10px;
  color: var(--color-text);
  text-transform: uppercase;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  text-align: center;
  border-radius: 7px;
}
.addNewCategory > p {
  width: 250px;
  font-size: 16px;
  color: var(--color-text);
  opacity: 0.7;
  margin-bottom: 20px;
}
