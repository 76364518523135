.FormNew {
  padding: 1.5vw 5vw;
}
.gridForm {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 1vw;
  row-gap: 1vw;
}
.productAdd {
  padding: 10px;
  cursor: pointer;
  background: #edf0fd;
  border-radius: 1vw;
  border: 1px solid #dcdcdc;
  /* box-shadow: 0.052083333333333336vw 0.3125vw 0.8625vw rgba(1, 1, 61, 0.4); */
}
.formInfo input {
  padding: 0.8vw;
  font-size: 0.9vw;
  margin-left: 1vw;
  border-radius: 5px;
  border: 1px solid black;
}
.productAdd img {
  width: 100%;
  border-radius: 0.7vw;
}
.productAdd p {
  margin: 5px 0;
  font-size: 0.9vw;
  font-weight: 600;
}
.FormNew h3 {
  font-size: 1.8vw;
  font-weight: 600;
}
.htmlRed {
  border: 1px solid #dcdcdc;
  border-radius: 0.7vw;
  overflow: hidden;
}
.saveB {
  padding: 0.8vw 1.5vw;
  background: #0370f3;
  color: #fff;
  font-size: 0.9vw;
  border-radius: 5px;
  cursor: pointer;
  margin-left: auto;
}
.formInfo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
.formInfo p {
  margin-left: auto;
  font-weight: 600;
  border: 1px solid #0370f3;
  border-radius: 5px;
  padding: 0.8vw;
  font-size: 0.9vw;
}
.formInfo p span {
  color: #0370f3;
  margin: 0 5px;
}
