.actions img {
  width: 22vw;
}
.actions {
  margin-bottom: 1vw;
  display: inline-block;
  margin-right: 1vw;
  position: relative;
  cursor: pointer;
}
.actionsCont {
  height: 80vh;
  overflow-y: scroll;

  width: 46.5vw;
  overflow-x: visible;
}
.flexCont {
  display: flex;
  justify-content: space-between;
}
.formActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15vw;
}
.formActions .button {
  width: 100%;
  text-align: center;
}
.panEm {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 1vw;
}
.panEm img {
  width: 4vw;
}
.panEm p {
  color: #fff;
  font-size: 1vw;
}
.panel {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(19, 44, 73, 0.35);
  width: 100%;
  height: 100%;
  border-radius: 10px;
  display: flex;
  align-items: flex-end;
  padding: 15px;
}
.imgAdd {
  background: #fff;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  padding-top: 0;
  box-shadow: 0px 0px 39px 0px rgba(19, 44, 73, 0.15);
  margin-bottom: 1.5vw;
  width: 100%;
}

.imgAdd label {
  padding: 0.5vw 2vw;
  font-size: 0.8vw;
  background: #0094ff;
  color: #fff;
  cursor: pointer;
  font-weight: bold;
  border-radius: 10px;
  margin-top: 1vw;
}
.imgAdd input {
  visibility: hidden;
}
.imgAdd p {
  text-align: center;
  font-weight: bolder;
  font-size: 1.2vw;
  line-height: 1em;
  background: #0094ff;
  color: #fff;
  padding: 10px 15px;
  border-radius: 0 0 1vw 1vw;
}
.imgAdd img {
  width: 3.5vw;
  height: 3.5vw;
}
.add_me {
  text-align: center;
  font-weight: bolder;
  font-size: 1.5vw;
  line-height: 1em;
  margin-bottom: 1vw;
}
