.orderElem {
  background: #fff;
  padding: 20px;

  border-radius: 1vw;
  -webkit-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  -moz-box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
  box-shadow: 0px 0px 8px 0px rgba(34, 60, 80, 0.2);
}
.ordersTab {
  padding: 15px !important;
}
.ordersButton img {
  width: 50px;
  margin-right: 20px;
  cursor: pointer;
}
.ordersButton {
  margin-top: 0.5vw;
}
.orderDetails {
  margin-right: auto;
  font-size: 0.7vw;
  color: #585e78;
  cursor: pointer;
  padding: 0.75vw 1.25vw;
  border: 1px solid #dee0ea;
  border-radius: 5px;
}
.typesOrder {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  font-size: 1vw;
  row-gap: 10px;
  margin-left: 0vw;
  padding-right: 0;
  margin-bottom: 1vw;
  width: 100%;
}
.lastType {
  flex: 1 1;

  height: 3.2vw;
}
.vkActive {
  background: #fff;
  border-radius: 0.5vw;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
}
.typesOrder div {
  width: auto;
  text-align: center;
  padding: 20px;
  display: flex;
  font-size: 16px;
  position: relative;
  color: var(--color-text);
  font-weight: 500;
  transition: 0s;
  align-items: center;
  cursor: pointer;
}
.orderBody span {
  display: block;
  margin-bottom: 0.6vw;
  font-size: 0.8vw;
}
.orderBody p {
  color: #0370f3;
  font-weight: 700;
  font-size: 1.1vw;
  margin-top: 0.3vw;
  margin-bottom: 1vw;
}
.orderBody {
}
.filters {
  display: flex;

  align-items: center;
  margin-left: 0;
  margin-bottom: 1vw;
  padding-left: 0;
}
.filters svg {
  width: 50px;
  padding: 11px;
  height: 50px;
  border-radius: 14px;
  background: #fff;
  border: 1px solid #ebebeb;
  margin-left: 0.5vw;
  cursor: pointer;
}
.rightFlt {
  margin-left: auto;
  display: flex;
}
.filters input[type="checkbox"] {
  margin-right: 0.5vw;
  width: 18px;
  height: 18px;
}
.filters > p {
  font-size: 0.7vw;
  font-weight: 400;
  color: var(--color-text);
}
.filters input[type="text"] {
  margin-left: 0;
  width: 240px;
  border: 1px solid #ebebeb;
  outline: none;
  background: #fff;

  font-size: 16px;

  border-radius: 12px;
}
.typesOrder div span {
  background: var(--color-text);
  margin-left: 0.4vw;
  color: #fff;
  width: 1vw;
  height: 1vw;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 0.6vw;
}
.typesOrder div p {
  transition: 0.1s;
}
.ordP .gridTitle {
  margin-bottom: 0;
}
.typesOrder div:hover {
  background: #fff;
}
.typesOrder div {
  margin-right: 0.5vw;
  transition: 0.3s;
  border-radius: 0.7vw;
}

.text {
  font-size: 0.8vw;
  display: flex;
  flex-wrap: wrap;
  grid-template-columns: 1fr 1fr;
}
.text p {
  margin-bottom: 0.3vw;
}
.orderElem > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.managerRow img {
  width: 2.5vw;
  height: 2.5vw;
  object-fit: cover;
  margin-right: 0.5vw;
  border-radius: 50%;
}
.stRel {
  position: relative;
}
.stRel:hover .changeSt {
  display: block;
}
.changeSt h3 {
  font-size: 16px;
}
.gridRow .changeSt {
  box-shadow: none;
  padding-bottom: 0;
  z-index: 5;
  box-shadow: rgba(0, 0, 0, 0.05) 0rem 1.25rem 1.6875rem 0rem;
  top: -1vw;

  border: 1px solid #ebebeb;
  right: 1.5vw;
}
.gridRow .changeSt p {
  margin: 16px 0;
  font-size: 16px;
  opacity: 0.7;
  font-weight: 400;
  min-width: 200px;
  text-align: left;
}
.ordP {
  padding-top: 2vw;
}
.ordP .descText {
  margin-bottom: 1vw;
}
.ordP .gridRow {
  border-bottom: 1px solid #ebebeb;
  padding: 12px 20px;
}
.opOrder {
  font-size: 16px;
  text-decoration: underline;
  opacity: 0.7;
}
.managerRow span {
  font-size: 14px;
}
.ordersColumn {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5vw;
  column-gap: 15px;
  padding: 1vw;
  margin-top: 1vw;

  overflow-y: scroll;
  max-height: 85vh;

  align-items: start;
}
.bord {
  position: relative;
}
.changeSt {
  position: absolute;
  top: 2.1vw;
  display: none;
  right: 0;
  background: #fff;
  font-size: 0.8vw;
  box-shadow: 0px 6px 30px rgba(58, 95, 190, 0.2);
  padding: 0.8vw;
  border-radius: 10px;
}
.statusOrder:hover .changeSt {
  display: block;
}
.changeSt p {
  text-align: right;
  margin-bottom: 0.3vw;
  cursor: pointer;
  transition: 0s;
}
.changeSt p:hover {
  font-weight: bold;
}
.status {
  width: fit-content;
  border: 1px solid black;
  padding: 0.5vw;
  border-radius: 0.5vw;
}
.status .statuses div,
.statusBody {
  padding: 0.3vw;
  cursor: pointer;
  font-size: 16px;
  width: fit-content;
}
.orderButtons {
  margin-top: 1vw;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
}
.statusOrder:hover {
  border-color: #0370f3;
}
.ordP .usersTable {
  animation: tops 0.5s;
}
.filters h3 {
  margin-right: 1vw;
  color: var(--color-text);
  font-size: 32px;
  font-weight: 600;
}
.statusOrder {
  transition: 0.3s;
  padding: 0.5vw 1vw;
  padding-right: 0.7vw;
  border-radius: 10px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  border: 1px solid #dee0ea;
}
.statusOrder svg {
  margin-left: 0.5vw;
}
.messageOrder {
  background: #0098c7;
  color: #fff;
  padding: 0.5vw 1vw;
  border-radius: 0.3vw;
  width: fit-content;
  display: flex;
  align-items: center;
  font-size: 0.8vw;
  cursor: pointer;
}
.sendMess {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  z-index: 20000;
}
.sendMessBody {
  background: #fff;
  padding: 1vw;
}
.status:hover .statuses {
  display: block;
}
.statuses {
  display: none;
}
.statuses div:hover {
  transform: translateX(5px);
}
.bord {
  border-bottom: 1px solid #dee0ea;
  padding-bottom: 0.8vw;
  margin-bottom: 0.8vw;
}
.titleOr {
  font-weight: bold;
  font-size: 1vw;
}
.ordersColumn h4 {
  text-align: center;
  font-size: 1vw;
}
.ordersColumn input {
  width: 100%;
  padding: 0.5vw;
  border-radius: 0.5vw;
  border: 1px solid black;
  margin-top: 1vw;
}
@keyframes tops {
  0% {
    transform: translateY(100px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
