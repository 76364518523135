.addNew {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: #fff;
  z-index: 99999;
  padding: 1vw;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  justify-content: flex-start;
}
.closeAdd {
  margin-left: auto;
  cursor: pointer;
}
.closeAdd svg {
  width: 60px !important;
  height: 60px !important;
}
.addNew h4 {
  font-size: 1.8vw;
  font-weight: 600;
  margin-left: 5vw;
}
.blocksList {
  padding: 0 5vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  width: 100%;
  column-gap: 1vw;
  margin-top: 1.5vw;
  row-gap: 1vw;
}
.blockItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.blockItem {
  background: #edf0fd;
  padding: 2vw 1vw;
  border-radius: 1vw;
  cursor: pointer;
}
.blockItem svg {
  width: 2.5vw;
  height: 2.5vw;
  margin-bottom: 10px;
}
.blockItem p {
  font-size: 1.4vw;
}
